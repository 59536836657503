var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: { title: "功能测试", width: 800, visible: _vm.visible },
      on: {
        ok: function ($event) {
          _vm.visible = false
        },
        cancel: function ($event) {
          _vm.visible = false
        },
      },
    },
    [
      _c(
        "a-form",
        { attrs: { form: _vm.form } },
        [
          _c(
            "a-form-item",
            { attrs: { label: "功能测试" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["test", _vm.validatorRules.test],
                    expression: "['test', validatorRules.test]",
                  },
                ],
                attrs: { placeholder: "请输入" },
                on: { change: (e) => (_vm.testValue = e.target.value) },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { type: "flex", gutter: 8 } },
        _vm._l(_vm.testValue, function (str, index) {
          return _c(
            "a-col",
            { key: index },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    [
                      _c("a-input", {
                        staticStyle: { "text-align": "center", width: "40px" },
                        attrs: { value: str },
                      }),
                    ],
                    1
                  ),
                  _c("a-col", { staticStyle: { "text-align": "center" } }, [
                    _vm._v(_vm._s(index + 1)),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }